import React from "react";
import { Map, TileLayer, WMSTileLayer } from "react-leaflet";
// import PropTypes from 'prop-types';
import "./Proto.scss";

//this can be turned into a function component
class ProtoMap extends React.Component {
  render() {
    const { uhiLayer, opacityValue, centerCoords, hostname } = this.props;
    if (hostname) {
      return (
        <Map
          center={[centerCoords.lat, centerCoords.lon]}
          zoom={11}
          // maxBounds={[
          //   //south west
          //   [45.079339209738094, -121.9317626953125],
          //   //north east
          //   [45.865150166790585, -123.46160888671875]
          // ]}
          ref={(map) => (this.leafletMap = map)}
        >
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            // attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
            ext="png"
            minZoom={5}
            maxZoom={17}
          />
          <TileLayer
            url="https://stamen-tiles-{s}.a.ssl.fastly.net/terrain-labels/{z}/{x}/{y}{r}.{ext}"
            // attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
            ext="png"
            minZoom={5}
            maxZoom={16}
          />
          <WMSTileLayer
            url={`http://${hostname}:8600/geoserver/uhi/wms`}
            layers={uhiLayer}
            format="image/png"
            transparent={true}
            opacity={opacityValue / 100}
            minZoom={5}
            maxZoom={17}
          />
        </Map>
      );
    }
    return null;
  }
}

export default ProtoMap;
