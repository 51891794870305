import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Nav from "./Navbar/Nav";
import "./App.scss";
import Home from "./Home/Home";
import About from "./About/About";
import Contact from "./Contact/Contact";
import UHIContainer from "./UHI/UHIContainer";
import RavesContainer from "./Raves/RavesContainer";
import PlantabilityContainer from "./Plantability/Plantability";
import ProtoContainer from "./GSProto/ProtoContainer";
import NoMatch from "./NoMatch/NoMatch";
import "../icons/iconsInit";
import { handleGetIndraHostname } from "../actions/indra";
import { handleGetGSLayers } from "../actions/data";

class App extends Component {
  //additonal geoserver logic
  componentDidMount() {
    this.props.dispatch(handleGetIndraHostname("/indra-ip"));
    this.props.dispatch(handleGetGSLayers("/gs-layers"));
  }

  render() {
    //gslayers
    const { gsLayers } = this.props.data;

    if (gsLayers !== null) {
      return (
        <Router>
          <React.Fragment>
            <Nav />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/about" component={About} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/built-environment" component={UHIContainer} />
              <Route
                exact
                path="/social-vulnerability"
                component={RavesContainer}
              />
              <Route
                exact
                path="/green-infrastructure"
                component={PlantabilityContainer}
              />
              {gsLayers.map((layer) => {
                return (
                  <Route
                    key={layer.name}
                    exact
                    path={layer.route}
                    component={ProtoContainer}
                  />
                );
              })}
              <Route component={NoMatch} />
            </Switch>
          </React.Fragment>
        </Router>
      );
    }
    return null;
  }
}
function mapStateToProps({ data }) {
  return { data };
}
export default connect(mapStateToProps)(App);
