export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

export function celsiusToFahrenheit(val) {
  const fahrenheit = val * (9 / 5) + 32;
  return Math.round(fahrenheit);
}

export function chartDataConverter(val) {
  //in F degrees
  const tempRangeF = 32; //was 25
  const maxF = 105;
  const y2 = tempRangeF - (maxF - val);
  const y1 = tempRangeF - y2;

  return { y1, y2 };
}

export function getCenterCoordsFromBoundingBox(inCoords) {
  const { minx, maxx, miny, maxy } = inCoords;
  const centerX = (minx + maxx) / 2;
  const centerY = (miny + maxy) / 2;
  return { lat: centerY, lon: centerX };
}

//dev
// export function getMaxExtentFromBoundingBox(inCoords){
//   const { minx, maxx, miny, maxy } = inCoords;

// }
