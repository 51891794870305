import { GET_INDRA_HOSTNAME } from "../actions/indra";

const initialState = { hostname: null };

export default function indra(state = initialState, action) {
  switch (action.type) {
    case GET_INDRA_HOSTNAME:
      return { ...state, ...action.payload };

    default:
      return state;
  }
}
