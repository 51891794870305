import React from 'react';
import ReactDOM from 'react-dom';
import './MapModal.scss';

//need this to use the react portal
const modalRoot = document.getElementById('modal-root');

class MapModal extends React.Component {
  render() {
    const { toggleModal, title, content } = this.props;
    return ReactDOM.createPortal(
      <React.Fragment>
        <div className="modal">
          <div className="modal-box">
            <div className="modal-text">
              <h2>{title}</h2>
              <div>{content}</div>
            </div>
            <button
              className="modal-button"
              onClick={() => {
                toggleModal();
              }}
            >
              Launch Demo
            </button>
          </div>
        </div>
      </React.Fragment>,
      modalRoot
    );
  }
}

export default MapModal;
