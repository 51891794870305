import React from 'react';
import { VictoryPie, VictoryLabel } from 'victory';
import {
  celsiusToFahrenheit,
  chartDataConverter
} from './../../utils/generalUtils';
import styleVars from './../../css/colors.scss';
import * as mitigation from './../../images/mitigation-white.png';
import * as paving from './../../images/paving-white.png';

//regular blue to yellow to red (not darkened)
// const colorPalette = [
//   '#0571b0',
//   '#378fc1',
//   '#6aadd1',
//   '#a4cfd8',
//   '#fbfcbb',
//   '#f6b48c',
//   '#e87666',
//   '#d93b43',
//   '#ca0020'
// ];

//from red to yellow to blue
const darkenPalette = [
  '#900000',
  '#c11e01',
  '#e98717',
  '#d0dd43',
  '#6fb648',
  '#219942',
  '#047546',
  '#00656b',
  '#00507c'
].reverse();

class UHICharts extends React.Component {
  //this is breaks in celsius
  getColor(d) {
    return d < 25 //28.5
      ? darkenPalette[0]
      : d < 27 //29
      ? darkenPalette[1]
      : d < 29 //31.5
      ? darkenPalette[2]
      : d < 31 //33
      ? darkenPalette[3]
      : d < 33 //34.5
      ? darkenPalette[4]
      : d < 35 //36
      ? darkenPalette[5]
      : d < 37 //37.5
      ? darkenPalette[6]
      : d < 39 //39
      ? darkenPalette[7]
      : darkenPalette[8];
  }

  render() {
    const { currentFeature } = this.props;
    console.log('current feature', currentFeature);

    if (currentFeature) {
      const data = currentFeature.properties;

      const noGreenMax = chartDataConverter(
        celsiusToFahrenheit(data.noGreen_MAX)
      );
      // const origMax = chartDataConverter(celsiusToFahrenheit(data.origB_MAX));
      // const allCombMax = chartDataConverter(
      //   celsiusToFahrenheit(data.allComb_MAX)
      // );
      // --------------
      // const origMean = chartDataConverter(celsiusToFahrenheit(data.origB_MEAN));
      const allCombMin = chartDataConverter(
        celsiusToFahrenheit(data.allComb_MIN)
      );

      return (
        <div className="uhi-charts-inner-container">
          <div className="uhi-labels">
            <div>Paving</div>
            <div>Greening</div>
          </div>
          <div className="uhi-charts">
            <div>
              <svg viewBox="0 0 400 400">
                <VictoryPie
                  standalone={false}
                  width={400}
                  height={400}
                  data={[
                    { x: 1, y: noGreenMax.y1 },
                    { x: 2, y: noGreenMax.y2 }
                  ]}
                  innerRadius={110}
                  labels={d => null} //no labels
                  colorScale={[
                    styleVars.light1,
                    this.getColor(data.noGreen_MAX)
                  ]}
                  // labelRadius={100}
                  // style={{ labels: { fontSize: 20, fill: 'white' } }}
                />
                <circle
                  cx="200"
                  cy="200"
                  r="100"
                  fill="none"
                  stroke="black"
                  strokeWidth={3}
                />
                <circle
                  cx="200"
                  cy="200"
                  r="155"
                  fill="none"
                  stroke="black"
                  strokeWidth={3}
                />
                <VictoryLabel
                  textAnchor="middle"
                  style={{
                    fontSize: 90,
                    fontWeight: 800,
                    fill: this.getColor(data.noGreen_MAX)
                  }}
                  x={200}
                  y={200}
                  text={`${celsiusToFahrenheit(
                    data.noGreen_MAX
                  ).toString()}\u00b0`}
                />
              </svg>
            </div>
            <div>
              <img src={paving} alt="paving" style={{ paddingTop: '10px' }} />
            </div>
            <div className="uhi-charts-divider" />
            <div>
              <img
                src={mitigation}
                alt="mitigation"
                style={{ paddingTop: '10px' }}
              />
            </div>

            <div>
              <svg viewBox="0 0 400 400">
                <VictoryPie
                  standalone={false}
                  width={400}
                  height={400}
                  data={[
                    { x: 1, y: allCombMin.y1 },
                    { x: 2, y: allCombMin.y2 }
                  ]}
                  innerRadius={110}
                  labels={d => null}
                  colorScale={[
                    styleVars.light1,
                    this.getColor(data.allComb_MIN)
                  ]}
                  // labelRadius={100}
                  // style={{ labels: { fontSize: 20, fill: 'white' } }}
                />
                <circle
                  cx="200"
                  cy="200"
                  r="100"
                  fill="none"
                  stroke="black"
                  strokeWidth={3}
                />
                <circle
                  cx="200"
                  cy="200"
                  r="155"
                  fill="none"
                  stroke="black"
                  strokeWidth={3}
                />
                <VictoryLabel
                  textAnchor="middle"
                  style={{
                    fontSize: 90,
                    fontWeight: 800,
                    fill: this.getColor(data.allComb_MIN)
                  }}
                  x={200}
                  y={200}
                  text={`${celsiusToFahrenheit(
                    data.allComb_MIN
                  ).toString()}\u00b0`}
                />
              </svg>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

export default UHICharts;

/* <div>
  <svg viewBox="0 0 400 400">
    <VictoryPie
      standalone={false}
      width={400}
      height={400}
      data={[{ x: 1, y: origMean.y1 }, { x: 2, y: origMean.y2 }]}
      innerRadius={80}
      labels={d => null}
      colorScale={[styleVars.dark5, this.getColor(data.origB_MEAN)]}
      // labelRadius={100}
      // style={{ labels: { fontSize: 20, fill: 'white' } }}
    />
    <circle
      cx="200"
      cy="200"
      r="65"
      fill="none"
      stroke="black"
      strokeWidth={3}
    />
    <circle
      cx="200"
      cy="200"
      r="155"
      fill="none"
      stroke="black"
      strokeWidth={3}
    />
    <VictoryLabel
      textAnchor="middle"
      style={{ fontSize: 60, fill: this.getColor(data.origB_MEAN) }}
      x={200}
      y={200}
      text={celsiusToFahrenheit(data.origB_MEAN).toString()}
    />
  </svg>
</div>; */

/* <div>
<VictoryChart
  height={300}
  width={500}
  domainPadding={40}
  theme={VictoryTheme.material}
  domain={{ x: [25, 35] }}
>
  <VictoryGroup
    horizontal
    offset={35}
    style={{ data: { width: 6 } }}
  >
    <VictoryBar
      range={{ x: [20, 45], y: [1,2] }}
      alignment="start"
      barWidth={20}
      style={{
        data: {
          fillOpacity: 0.7,
          strokeWidth: 3
        }
      }}
      data={[
        {
          x: 1,
          y: currentFeature.properties.origB_MIN
        },
        {
          x: 2,
          y: currentFeature.properties.allComb_MIN
        }
      ]}
    />
    <VictoryBar
      range={{ x: [20, 45], y: [1,2] }}
      alignment="start"
      barWidth={20}
      style={{
        data: {
          fillOpacity: 0.7,
          strokeWidth: 3
        }
      }}
      data={[
        {
          x: 1,
          y: currentFeature.properties.origB_MAX
        },
        {
          x: 2,
          y: currentFeature.properties.allComb_MAX
        }
      ]}
    />
    <VictoryAxis />
  </VictoryGroup>
</VictoryChart>
</div> */

// ---------------------------------------------------
/* <div>
  <VictoryPie
    padAngle={3}
    innerRadius={50}
    height={250}
    width={300}
    theme={VictoryTheme.material}
    labelComponent={<VictoryLabel angle={30} />}
    data={[
      { x: 'value 1', y: data.VALUE_1 / data.TOTAL },
      { x: 'value 2', y: data.VALUE_2 / data.TOTAL },
      { x: 'value 3', y: data.VALUE_3 / data.TOTAL },
      { x: 'value 4', y: data.VALUE_4 / data.TOTAL },
      { x: 'value 5', y: data.VALUE_5 / data.TOTAL },
      { x: 'value 6', y: data.VALUE_6 / data.TOTAL },
      { x: 'value 7', y: data.VALUE_7 / data.TOTAL }
    ]}
  />
</div>; */

/* <div>
  <div>Land Type</div>
  <VictoryChart polar height={250} width={300} theme={VictoryTheme.material}>
    {[
      'high canopy',
      'urban districts \n& corridors',
      'medium canopy',
      'hardscape \nindustrial',
      'vegetated urban',
      'semi-rural',
      'hillside forest'
    ].map((d, i) => {
      return (
        <VictoryPolarAxis
          dependentAxis
          key={i}
          label={d}
          labelPlacement="perpendicular"
          style={{ tickLabels: { fill: 'none' } }}
          axisValue={i}
        />
      );
    })}
    <VictoryBar
      style={{
        data: {
          fill: d => this.getPolarStyle(d._x),
          stroke: 'black',
          strokeWidth: 2,
          width: 25
        },
        labels: { fontSize: 12 },
        parent: { border: '1px solid #ccc' }
      }}
      data={[
        { y: data.VALUE_1 },
        { y: data.VALUE_2 },
        { y: data.VALUE_3 },
        { y: data.VALUE_4 },
        { y: data.VALUE_5 },
        { y: data.VALUE_6 },
        { y: data.VALUE_7 }
      ]}
    />
  </VictoryChart>
</div>; */

/* <div>
  <div>Temperature</div>
  <VictoryChart
    height={250}
    width={300}
    theme={VictoryTheme.material}
    domain={{ x: [20, 35] }}
  >
    <VictoryGroup
      horizontal
      offset={30}
      style={{ data: { width: 25 } }}
      colorScale={['brown', 'tomato', 'gold']}
    >
      <VictoryBar
        // y0={d => d.y - 10}
        data={[
          { x: 1, y: data.allComb_MIN, y0: 20 },
          { x: 2, y: data.origB_MIN, y0: 20 }
        ]}
      />
      <VictoryBar
        data={[
          { x: 1, y: data.allComb_MAX, y0: 20 },
          { x: 2, y: data.origB_MAX, y0: 20 }
        ]}
      />
    </VictoryGroup>
    <VictoryAxis />
  </VictoryChart>
</div>; */
