export const SET_OPACITY = "SET_OPACITY";
export const SET_CURRENT_LAYER = "SET_CURRENT_LAYER";
export const SET_FILTERED_GS_LAYERS = "SET_FILTERED_GS_LAYERS";

export function setOpacity(val) {
  return { type: SET_OPACITY, payload: val };
}

export function setFilteredGsLayers(filteredLayers) {
  return {
    type: SET_FILTERED_GS_LAYERS,
    payload: filteredLayers
  };
}

export function setCurrentLayer(layer) {
  return { type: SET_CURRENT_LAYER, payload: { layer } };
}
