import React from "react";

const NoMatch = () => {
  return (
    <section className="">
      <div className="">This page does not exist.</div>
    </section>
  );
};

export default NoMatch;
