import React from 'react';
// import PropTypes from 'prop-types';

const About = props => {
  return <div>About</div>;
};

export default About;


// class About extends React.Component {
//   state = {
//     loading: true
//   };

//   handleLoading = () => {
//     this.setState({ loading: false });
//   };

//   render() {
//     const { loading } = this.state;

//     return (
//       <div className="plantability-container">
//         {loading ? <Loading/> : null}
//         <Iframe
//           onLoad={this.handleLoading}
//           url="https://capastrategies.com/our-story/"
//           width="100%"
//           height="100%"
//           //   className="plantability-container"
//           //   display="initial"
//           position="relative"
//           allowFullScreen
//         />
//       </div>
//     );
//   }
// }