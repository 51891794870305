import fetch from "node-fetch";

//to deal with dev and prod routes
const urlLocation = new URL(window.location.href);
const urlOrigin = urlLocation.origin;
let url;
if (urlOrigin.includes("localhost")) {
  url = "http://localhost:5000";
  console.log("dev path: ", url);
} else {
  url = urlOrigin;
  console.log("prod path: ", urlOrigin);
}

export async function fetchCBGData(route) {
  // 'http://localhost:5000/pdxZones_withZs.json'
  // 'http://localhost:5000/pdxBgs_origB_allComb.json'
  const urlFull = `${url}${route}`;
  const data = await fetch(urlFull)
    .catch((e) => console.log("error", e))
    .then((response) => response.json())
    .then((json) => json);
  return data;
}

//GS Utils//
//This is used  in redux action
export async function getGSLayers(route) {
  const gsLayers = await fetch(`${url}${route}`)
    .then((response) => response.json())
    .then((json) => json)
    .catch((e) => console.log("error: ", e));

  return { ...gsLayers };
}

export async function getUHILayerDetails(route) {
  const uhiLayerDetails = await fetch(`${url}${route}`)
    .then((response) => response.json())
    .then((json) => json)
    .catch((e) => console.log("error: ", e));
  return { ...uhiLayerDetails };
}

//indra utils
export async function getIndraHostname(route) {
  const response = await fetch(`${url}${route}`);

  if (response.ok) {
    const json = await response.json();
    return json;
  } else {
    throw new Error(response.status);
  }
}
