import React from 'react';
import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';
import * as uhiCover from './../../images/uhi-cover.png';
import * as ravesCover from './../../images/raves-cover.png';
import * as plantabilityCover from './../../images/plantability-cover.png';
import './Home.scss';

const Home = props => {
  return (
    <React.Fragment>
      <div className="home-container">
        <div className="home-logo-container">
          <div className="home-header-container">
            <div className="header-content-container">
              <div className="header-content">
                <h1 className="content-words">CAPA INSIGHTS</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="map-selection-container">
          <div className="map-selection-content-container">
            <div className="map-selection-content">
              <h2>DECISION SUPPORT FOR CLIMATE PLANNING</h2>
              <div className="map-selection-content-text">
                <p>
                  When it comes to preparing our communities, cities, and
                  organizations for extreme climate events, we need accurate
                  information that is easy to use and immediately actionable. We
                  work with you to customize online applications that are can
                  help you identify vulnerabilities in your region, and inform
                  decision making. Our system-approach relies on the best
                  available data to climate-proof against risks to the social,
                  physical, and ecological fabric of your community.
                </p>
                <p>
                  Below we provide three demos of decision support tools that
                  we've built for our clients. They all use the City of
                  Portland, Oregon to illustrate the different ways that
                  available data can be used to isolate social and
                  infrastructure vulnerabilities, and identify approaches for
                  mitigation. Each of the demos provide information about its
                  purpose, the data, and how it is being used. As you'll notice
                  they are interactive, evidence-based, and provide you with
                  insights about where and how to target your preparedness
                  efforts. Go ahead, click one of the demos below and explore
                  what is possible!
                </p>
              </div>
            </div>
          </div>
          <div className="map-options-container">
            <div>
              <Link to="/social-vulnerability">
                <div className="map-icon">
                  <div>
                    <img
                      src={ravesCover}
                      alt="Social Vulnerability to Extreme Heat"
                    />
                  </div>
                  <span>Demo 1</span>
                </div>
              </Link>
            </div>
            <div>
              <Link to="/built-environment">
                <div className="map-icon">
                  <div>
                    <img
                      src={uhiCover}
                      alt="Scenarios of Changing the Built Environment"
                    />
                  </div>
                  <span>Demo 2</span>
                </div>
              </Link>
            </div>
            <div>
              <Link to="/green-infrastructure">
                <div className="map-icon">
                  <div>
                    <img
                      src={plantabilityCover}
                      alt="Expanding Green Infrastructure"
                    />
                  </div>
                  <span>Demo 3</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="copy">© CAPA Strategies, llc.</div>
    </React.Fragment>
  );
};

export default Home;

// return (
//   <div className="home-container">
//     <div className="ci-logo-container">
//       <img className="ci-logo-img" src={ciLogo} alt="city-insights-tool" />
//       <div className="ci-tagline">TOOLS FOR CLIMATE PLANNING</div>

//       <div className="map-selection-container">
//         <Link to="/plantability"><span>map1</span></Link>
//         <Link to="/raves"><span>map2</span></Link>
//         <Link to="/uhi">
//           <span>map3</span>
//         </Link>
//       </div>
//     </div>
//     <div className="copy">© CAPA Strategies, llc.</div>
//   </div>
// );
