import {
  getGSLayers,
  fetchCBGData,
  getUHILayerDetails
} from "../utils/fetchUtils";

export const GET_GS_LAYERS = "GET_GS_LAYERS";
export const GET_CBG_DATA = "GET_CBG_DATA";
export const GET_UHI_LAYER_DETAILS = "GET_UHI_LAYER_DETAILS";

function gsLayers(gsLayers) {
  return {
    type: GET_GS_LAYERS,
    payload: gsLayers
  };
}

function cbgData(data) {
  return {
    type: GET_CBG_DATA,
    payload: data
  };
}

function uhiLayerDetails(details) {
  return {
    type: GET_UHI_LAYER_DETAILS,
    payload: details
  };
}

export function handleGetGSLayers(route) {
  return dispatch => {
    return getGSLayers(route)
      .then(data => {
        dispatch(gsLayers(null));
        dispatch(gsLayers(data));
      })
      .catch(err => {
        console.log(err);
      });
  };
}

export function handleGetCBGData(route) {
  return dispatch => {
    return fetchCBGData(route)
      .then(data => {
        dispatch(cbgData({ cbg: null }));
        dispatch(cbgData({ cbg: data }));
      })
      .catch(err => {
        console.log(err);
      });
  };
}

export function handleGetUHILayerDetails(route) {
  return dispatch => {
    return getUHILayerDetails(route)
      .then(data => {
        dispatch(uhiLayerDetails({ uhiLayerDetails: null }));
        dispatch(uhiLayerDetails({ uhiLayerDetails: data }));
      })
      .catch(err => {
        console.log(err);
      });
  };
}
