import React from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import { fetchCBGData } from "../../utils/fetchUtils";
import { getKeyByValue } from "../../utils/generalUtils";
import RavesMap from "./RavesMap";
import RavesCharts from "./RavesCharts";
import MapModal from "./../Utils/MapModal";
import MapDrawer from "./../Utils/MapDrawer";
import Loading from "./../Utils/Loading";
// import * as morning from './../../images/morning-uhi-white.png';
// import * as afternoon from './../../images/afternoon-uhi-white.png';
// import * as evening from './../../images/evening-uhi-white.png';
import styleVars from "./../../css/colors.scss";

//content for modal
const modalContent = {
  title: "Social Vulnerability to Extreme Heat",
  content:
    "Like other climate-induced events, heat waves affect those with least " +
    "access to cooling resources, killing more people per year than all " +
    "other natural disasters combined. Finding locations where " +
    "communities may be at risk is a first step to taking action. Using " +
    "heat data that was collected through the CAPA Heat Watch program, " +
    "and publicly available demographic information, this demo provides a " +
    "description of neighborhoods in the City of Portland that vary in " +
    "their ambient temperatures during a heat wave. Explore the " +
    "intersection of one climate stressor -- urban heat -- and those " +
    "local communities most that face acute impacts from a warming " +
    "planet."
};

//images for map selection (THESE ARE NOT USED CURRENTLY)
// const controlImages = [morning, afternoon, evening];

//text names instead of images
const controlNames = ["morning (6am)", "afternoon (3pm)", "evening (8pm)"];

const MapControls = props => {
  const { controller, uhiLayerName } = props;

  return (
    <div className="raves-controls-container">
      <div className="raves-controls-container-title">Temperatures</div>
      <div className="raves-controls-selectors">
        {["Morning", "Afternoon", "Evening"].map((item, index) => (
          <div
            data-tip={item + " Traverse"}
            // className="control-item"
            className="control-text-item"
            key={item}
            onClick={() => controller(item)}
            style={
              item === uhiLayerName
                ? // ? { border: `1px solid ${styleVars.light1}` } //for images
                  {
                    background: `${styleVars.dark5}`,
                    color: styleVars.leafletcolor
                  }
                : null
            }
          >
            {controlNames[index]}
            {/* THE FOLLOWING img TAG WAS CAN BE USED TO INSTEAD OF TEXT */}
            {/* <img src={controlImages[index]} alt={item} /> */}
            <ReactTooltip />
          </div>
        ))}
      </div>
    </div>
  );
};
class RavesContainer extends React.Component {
  state = {
    currentFeature: null,
    uhiLayerName: "Morning",
    uhiLayer: "https://climatecope.research.pdx.edu/UHI/data/a/{z}/{x}/{y}.png",
    transparencyValue: 90,
    data: null,
    showModal: true
  };

  //method to toggle modal
  toggleModal = () => {
    this.setState({ showModal: false });
  };

  //set object with all the tile layers
  setTileLayer = name => {
    const uhiLayerUrls = {
      Morning:
        "https://climatecope.research.pdx.edu/UHI/data/a/{z}/{x}/{y}.png",
      Afternoon:
        "https://climatecope.research.pdx.edu/UHI/data/b/{z}/{x}/{y}.png",
      Evening: "https://climatecope.research.pdx.edu/UHI/data/c/{z}/{x}/{y}.png"
    };

    this.setState({
      uhiLayer: uhiLayerUrls[name],
      uhiLayerName: getKeyByValue(uhiLayerUrls, uhiLayerUrls[name])
    });
  };

  //send this as props down to the geojson layer
  setCurrentFeature = currentFeature => {
    this.setState({ currentFeature });
  };

  handleSliderChange = transparencyValue => {
    this.setState({
      transparencyValue: transparencyValue
    });
  };

  async componentDidMount() {
    const data = await fetchCBGData("/CBG_all.json");
    this.setState({ data });
  }

  render() {
    const {
      data,
      currentFeature,
      transparencyValue,
      uhiLayer,
      uhiLayerName,
      showModal
    } = this.state;
    return data ? (
      <React.Fragment>
        <div className="raves-container">
          <MapControls
            controller={this.setTileLayer}
            uhiLayerName={uhiLayerName}
          />

          <RavesMap
            setFeature={this.setCurrentFeature}
            data={data}
            uhiLayer={uhiLayer}
            transparencyValue={transparencyValue}
          />

          {currentFeature ? (
            <RavesCharts currentFeature={currentFeature} />
          ) : null}
          <div className="raves-slider-container">
            <Slider
              min={0}
              max={100}
              value={transparencyValue}
              orientation="vertical"
              onChange={this.handleSliderChange}
            />
          </div>
          <MapDrawer content="raves" />
        </div>
        {showModal ? (
          <MapModal
            toggleModal={this.toggleModal}
            title={modalContent.title}
            content={modalContent.content}
          />
        ) : null}
      </React.Fragment>
    ) : (
      <Loading />
    );
  }
}

export default RavesContainer;
