import {
  SET_OPACITY,
  SET_CURRENT_LAYER,
  SET_FILTERED_GS_LAYERS
} from "../actions/mapControls";

const initialState = {
  opacityValue: 100,
  currentLayer: null,
  filteredLayers: null
};

export default function mapControls(state = initialState, action) {
  switch (action.type) {
    case SET_OPACITY:
      return { ...state, opacityValue: action.payload };
    case SET_CURRENT_LAYER:
      return { ...state, currentLayer: action.payload };
    case SET_FILTERED_GS_LAYERS:
      return { ...state, filteredLayers: action.payload };
    default:
      return state;
  }
}
