import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBars,
  faPlus,
  faMinus,
  faSearch,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faPrint,
  faSave,
  faMapMarker,
  faSpinner
} from '@fortawesome/free-solid-svg-icons';


const appIcons = {
  hamburgerBars: faBars,
  plus: faPlus,
  minus: faMinus,
  search: faSearch,
  angleDown: faAngleDown,
  angleLeft: faAngleLeft,
  angleRight: faAngleRight,
  print: faPrint,
  save: faSave,
  mapMarker: faMapMarker,
  spinner: faSpinner
};

//add them to the library to be used in all components
//import this script in the App component
Object.values(appIcons).map(icon => library.add(icon));