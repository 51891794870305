import { getIndraHostname } from "../utils/fetchUtils";

export const GET_INDRA_HOSTNAME = "GET_INDRA_HOSTNAME";

function indraHostname(hostname) {
  return {
    type: GET_INDRA_HOSTNAME,
    payload: hostname,
  };
}

export function handleGetIndraHostname(route) {
  return (dispatch) => {
    return getIndraHostname(route)
      .then((data) => {
        dispatch(indraHostname(data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
