import data from "./data";
import mapControls from "./mapControls";
import indra from "./indra";
import { combineReducers } from "redux";

export default combineReducers({
  data,
  mapControls,
  indra,
});
