import L from 'leaflet';

//to deal with dev and pord paths
const urlLocation = new URL(window.location.href);
const urlOrigin = urlLocation.origin;
let url;
if (urlOrigin.includes('localhost')) {
  url = 'http://localhost:5000';
  console.log('dev path: ', url);
} else {
  url = urlOrigin;
  console.log('prod path: ', urlOrigin);
}

const maxZoom = 18;
const minZoom = 11;
const ext = 'png';

const stamenTonerLight = {
  url:
    'https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.{ext}',
  options: {
    attribution:
      'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    subdomains: 'abcd',
    minZoom: maxZoom,
    maxZoom: minZoom,
    ext: ext
  }
};

const stamenTonerDark = {
  url: 'https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.{ext}',
  options: {
    attribution:
      'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    subdomains: 'abcd',
    minZoom: minZoom,
    maxZoom: maxZoom,
    ext: ext
  }
};

// const allCombLayer = {
//   url: `${url}/all-comb/{z}/{x}/{y}.png`,
//   options: {
//     subdomains: 'abcd',
//     minZoom: minZoom,
//     maxZoom: maxZoom,
//     ext: ext
//   }
// };

// const originalBLayer = {
//   url: `${url}/original-b/{z}/{x}/{y}.png`,
//   options: {
//     subdomains: 'abcd',
//     minZoom: minZoom,
//     maxZoom: maxZoom,
//     ext: ext
//   }
// };

// const originalBLayerStretch = {
//   url: `${url}/original_b_1x1_stretched/{z}/{x}/{y}.png`,
//   options: {
//     subdomains: 'abcd',
//     minZoom: minZoom,
//     maxZoom: maxZoom,
//     ext: ext
//   }
// };

const noGreenLayerStretch = {
  url: `${url}/nogr_dark_web_1x1_stretched/{z}/{x}/{y}.png`,
  options: {
    subdomains: 'abcd',
    minZoom: minZoom,
    maxZoom: maxZoom,
    ext: ext
  }
};

const allCombLayerStretch = {
  url: `${url}/all_comb_web_1x1_stretched/{z}/{x}/{y}.png`,
  options: {
    subdomains: 'abcd',
    minZoom: minZoom,
    maxZoom: maxZoom,
    ext: ext
  }
};

export const tileLayers = {
  stamenTonerLight: L.tileLayer(stamenTonerLight.url, stamenTonerLight.options),
  stamenTonerDark: L.tileLayer(stamenTonerDark.url, stamenTonerDark.options),
  // allCombLayer: L.tileLayer(allCombLayer.url, allCombLayer.options),
  // originalBLayer: L.tileLayer(originalBLayer.url, originalBLayer.options),
  lOriginalBLayerStretch: L.tileLayer(
    // originalBLayerStretch.url,
    // originalBLayerStretch.options
  ),
  lNoGreenLayerStretch: L.tileLayer(
    noGreenLayerStretch.url,
    noGreenLayerStretch.options
  ),
  lAllCombLayerStretch: L.tileLayer(
    allCombLayerStretch.url,
    allCombLayerStretch.options
  ),
  // rOriginalBLayerStretch: L.tileLayer(
  //   originalBLayerStretch.url,
  //   originalBLayerStretch.options
  // ),
  rNoGreenLayerStretch: L.tileLayer(
    noGreenLayerStretch.url,
    noGreenLayerStretch.options
  ),
  rAllCombLayerStretch: L.tileLayer(
    allCombLayerStretch.url,
    allCombLayerStretch.options
  )
};
