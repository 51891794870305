import React from 'react';
import Iframe from 'react-iframe';
import Loading from './../Utils/Loading'
import './Plantability.scss';

class PlantabilityContainer extends React.Component {
  state = {
    loading: true
  };

  handleLoading = () => {
    this.setState({ loading: false });
  };

  render() {
    const { loading } = this.state;

    return (
      <div className="plantability-container">
        {loading ? <Loading/> : null}
        <Iframe
          onLoad={this.handleLoading}
          url="https://climatecope.research.pdx.edu/plant/"
          width="100%"
          height="100%"
          //   className="plantability-container"
          //   display="initial"
          position="relative"
          allowFullScreen
        />
      </div>
    );
  }
}

export default PlantabilityContainer;
