import React from 'react';
import MediaQuery from 'react-responsive';
import { NavLink } from 'react-router-dom';
import './Nav.scss';
import * as logo from './../../images/city-insights-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSTransition } from 'react-transition-group';

const NavDrawer = ({ navVisible, onClick, linkToggle }) => (
  <CSSTransition
    in={navVisible}
    timeout={200}
    classNames="navdrawer"
    appear={true}
  >
    {status => {
      return (
        <div className="navdrawer">
          {/* <NavLink
            className="nav-drawer-item"
            activeClassName="nav-drawer-item-active"
            exact
            to="/about"
            onClick={() => {
              onClick();
              linkToggle('about');
            }}
          >
            About
          </NavLink> */}
          {/* <NavLink
            className="nav-drawer-item"
            activeClassName="nav-drawer-item-active"
            exact
            to="/contact"
            onClick={() => {
              onClick();
              linkToggle('contact');
            }}
          > */}
          <a href={'//capastrategies.com/contact-us/'}>
            <div className="nav-drawer-item">Contact Us</div>
          </a>
          {/* </NavLink> */}
        </div>
      );
    }}
  </CSSTransition>
);

class Nav extends React.Component {
  state = {
    navDrawerVisible: false,
    selectedLink: null
  };

  toggleDrawer = () => {
    this.setState(prevState => ({
      navDrawerVisible: !prevState.navDrawerVisible
    }));
  };

  logoDrawerToggle = () => this.setState(() => ({ navDrawerVisible: false }));

  linkToggle = selected => {
    this.setState(() => ({ selectedLink: selected }));
  };

  componentDidMount() {
    const nav = new URL(window.location.href).pathname.replace('/', '');
    this.setState(() => ({ selectedLink: nav }));
  }

  render() {
    const { navDrawerVisible, selectedLink } = this.state;

    return (
      <div className="nav">
        <header>
          <nav className="nav-container">
            <div className="logo">
              <NavLink
                exact
                activeClassName="logo-active"
                to="/"
                onClick={() => {
                  this.logoDrawerToggle();
                  this.linkToggle(null);
                }}
              >
                <div className="logo">
                  <img src={logo} alt="logo" />
                  {/* <span>CAPA INSIGHTS</span> */}
                </div>
              </NavLink>
            </div>
            <div className="spacer" />
            <MediaQuery query="(min-width: 600px)">
              {/* <NavLink
                className="nav-item"
                exact
                activeClassName="nav-item-active"
                to="/about"
                onClick={() => {
                  this.linkToggle('about');
                }}
              >
                <div>
                  About
                  <span
                    className={
                      selectedLink === 'about' ? 'nav-underline' : null
                    }
                  />
                </div>
              </NavLink> */}
              {/* <NavLink
                className="nav-item"
                exact
                activeClassName="nav-item-active"
                to="/contact"
                onClick={() => {
                  this.linkToggle('contact');
                }}
              > */}
              <a href={'//capastrategies.com/contact-us/'}>
                <div className="nav-item">
                  Contact Us
                  <span
                    className={
                      selectedLink === 'contact' ? 'nav-underline' : null
                    }
                  />
                </div>
              </a>
              {/* </NavLink> */}
            </MediaQuery>
            <MediaQuery query="(max-width: 599px)">
              <div className="hamburger-button">
                <FontAwesomeIcon
                  // style={styles.content}
                  icon="bars"
                  size="2x"
                  onClick={this.toggleDrawer}
                />
              </div>
            </MediaQuery>
          </nav>
          <MediaQuery query="(max-width: 599.999999px)">
            <NavDrawer
              onClick={this.toggleDrawer}
              navVisible={navDrawerVisible}
              linkToggle={this.linkToggle}
            />
          </MediaQuery>
        </header>
      </div>
    );
  }
}

export default Nav;
