import React from "react";
import ReactDOM from "react-dom";
//redux
import { createStore } from "redux";
import { Provider } from "react-redux";
import reducer from "./reducers";
import middleware from "./middleware";
//
import WebFont from "webfontloader";
import L from "leaflet";
import "./index.scss";
import App from "./components/App";
import "leaflet/dist/leaflet.css"; //regular leaflet

//redux store
const store = createStore(reducer, middleware);
console.log('Initial store: ', store.getState());
//load google fonts
WebFont.load({
  google: {
    families: ["Titillium Web", "Oswald", "Roboto", "Ubuntu"]
  }
});

//annoying hack to deal with webpack and marker icon
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
