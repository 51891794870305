import { MapControl, withLeaflet } from 'react-leaflet';
import PropTypes from 'prop-types';
import L from 'leaflet';
import './UHI.scss';
import 'leaflet-side-by-side';

class SideBySide extends MapControl {
  static propTypes = {
    leftLayers: PropTypes.array.isRequired,
    rightLayers: PropTypes.array.isRequired,
    transparencyValue: PropTypes.number.isRequired
  };

  createLeafletElement(props) {
    console.log('props', props);

    const { map } = props.leaflet || this.context;
    const { transparencyValue } = props;
    this.map = map;
    //------------------------------------------
    this.leftLayers = props.leftLayers.map((layer, index) => {
      // console.log('sbs props left layer', layer);
      // const tileLayer = L.tileLayer(layer.url, layer.options);
      // this.setState({ leftTileLayer: tileLayer });
      // tileLayer.remove();
      return layer
        .setOpacity(transparencyValue / 100)
        .addTo(map)
        .setZIndex(index + 2);
    });

    this.rightLayers = props.rightLayers.map((layer, index) => {
      // const tileLayer = L.tileLayer(layer.url, layer.options).addTo(map);
      return layer.addTo(map).setZIndex(index + 2);
    });

    const sideBySideMap = L.control.sideBySide();
    sideBySideMap.setRightLayers(this.rightLayers);
    sideBySideMap.setLeftLayers(this.leftLayers);
    this.sideBySide = sideBySideMap.addTo(map);
    return this.sideBySide;
  }

  componentDidMount() {
    super.componentDidMount();
  }

  //handle changle tile layer props
  //sent from UHI Container
  componentDidUpdate(prevProps) {
    //set the opacity using the transparency prop
    if (this.props.transparencyValue !== prevProps.transparencyValue) {
      console.log('new tranparency');
      this.rightLayers = this.props.rightLayers[0].setOpacity(
        this.props.transparencyValue / 100
      );
      this.leftLayers = this.props.leftLayers[0].setOpacity(
        this.props.transparencyValue / 100
      );
    } else if (
      this.props.leftLayers[0] !== prevProps.leftLayers[0] ||
      this.props.rightLayers[0] !== prevProps.rightLayers[0]
    ) {
      this.sideBySide.remove();
      prevProps.leftLayers[0].remove();
      prevProps.rightLayers[0].remove();

      this.leftLayers = this.props.leftLayers[0]
        .setOpacity(this.props.transparencyValue / 100)
        .addTo(this.map)
        .setZIndex(3);
      this.rightLayers = this.props.rightLayers[0]
        .setOpacity(this.props.transparencyValue / 100)
        .addTo(this.map)
        .setZIndex(3);

      const sideBySideMap = L.control.sideBySide();
      sideBySideMap.setLeftLayers(this.leftLayers);
      sideBySideMap.setRightLayers(this.rightLayers);
      this.sideBySide = sideBySideMap.addTo(this.map);
      return this.sideBySide;
    }
  }
}

export default withLeaflet(SideBySide);

//------------------------------------------
// const urlLocation = new URL(window.location.href);
// const urlOrigin = urlLocation.origin;
// let url;
// if (urlOrigin.includes('localhost')) {
//   url = 'http://localhost:5000';
//   console.log('dev path: ', url);
// } else {
//   url = urlOrigin;
//   console.log('prod path: ', urlOrigin);
// }

//define all the layers
// const stamenLayer1 = L.tileLayer(
//   'https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.{ext}',
//   {
//     attribution:
//       'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
//     subdomains: 'abcd',
//     minZoom: 10,
//     maxZoom: 16,
//     ext: 'png'
//   }
// ).addTo(map);

// const stamenDarkLayer1 = L.tileLayer(
//   'https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.{ext}',
//   {
//     attribution:
//       'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
//     subdomains: 'abcd',
//     minZoom: 0,
//     maxZoom: 20,
//     ext: 'png'
//   }
// ).addTo(map);

// const allCombLayer = L.tileLayer(`${url}/all-comb/{z}/{x}/{y}.png`, {
//   attribution:
//     'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
//   ext: 'png'
// }).setZIndex(8).addTo(map);

// const originalBLayer = L.tileLayer(`${url}/original-b/{z}/{x}/{y}.png`, {
//   attribution:
//     'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
//   subdomains: 'abcd',
//   minZoom: 0,
//   maxZoom: 20,
//   ext: 'png'
// }).setZIndex(8).addTo(map);

// console.log(originalBLayer, allCombLayer);

// const sideBySide = L.control.sideBySide();
// sideBySide.setLeftLayers(originalBLayer);
// sideBySide.setRightLayers(allCombLayer);
// return sideBySide.addTo(map);
