import React from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import Slider from "react-rangeslider";
import {
  setOpacity,
  setCurrentLayer,
  setFilteredGsLayers,
} from "../../actions/mapControls";
import { handleGetUHILayerDetails } from "../../actions/data";
import { getCenterCoordsFromBoundingBox } from "../../utils/generalUtils";
import ProtoMap from "./ProtoMap";
import MapModal from "../Utils/MapModal";
import MapDrawer from "../Utils/MapDrawer";
import Loading from "../Utils/Loading";
import "react-rangeslider/lib/index.css";
import styleVars from "./../../css/colors.scss";

//content for modal
const modalContent = {
  title: "Prototype",
  content: `This is a prototype using Geoserver WMS.`,
};

class MapControls extends React.Component {
  controlNames = ["morning (6am)", "afternoon (3pm)", "evening (8pm)"];

  //This can be converted into an action
  setTileLayer = (name) => {
    const { filteredLayers } = this.props;
    //these may need to be sorted later
    const uhiLayerUrls = {
      Morning: `uhi:${filteredLayers[1].name}`,
      Afternoon: `uhi:${filteredLayers[0].name}`,
      Evening: `uhi:${filteredLayers[2].name}`,
    };

    this.props.dispatch(setCurrentLayer({ [name]: uhiLayerUrls[name] }));
  };

  render() {
    const { uhiLayerName } = this.props;
    const traverseTime = Object.keys(uhiLayerName)[0];
    return (
      <div className="proto-controls-container">
        <div className="proto-controls-container-title">Temperatures</div>
        <div className="proto-controls-selectors">
          {["Morning", "Afternoon", "Evening"].map((item, index) => (
            <div
              data-tip={item + " Traverse"}
              className="control-text-item"
              key={item}
              onClick={() => this.setTileLayer(item)}
              style={
                item === traverseTime
                  ? {
                      background: `${styleVars.dark5}`,
                      color: styleVars.leafletcolor,
                    }
                  : null
              }
            >
              {this.controlNames[index]}
              <ReactTooltip />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

class ProtoContainer extends React.Component {
  state = {
    showModal: true,
  };

  //method to toggle modal
  toggleModal = () => {
    this.setState({ showModal: false });
  };

  componentDidMount() {
    const { filteredLayers, morningTraverse } = this.props;
    this.props.dispatch(setFilteredGsLayers(filteredLayers));
    this.props.dispatch(setCurrentLayer({ Morning: `uhi:${morningTraverse}` })); //layer name
    this.props.dispatch(
      handleGetUHILayerDetails(`/gsdetails/${morningTraverse}`)
    );
  }

  render() {
    const {
      opacityValue,
      currentLayer,
      filteredLayers,
      uhiLayer,
      centerCoords,
      hostname,
    } = this.props;
    const { showModal } = this.state;

    return uhiLayer && centerCoords ? (
      <React.Fragment>
        <div className="proto-container">
          <MapControls
            uhiLayerName={currentLayer.layer}
            filteredLayers={filteredLayers}
            dispatch={this.props.dispatch}
          />

          <ProtoMap
            uhiLayer={uhiLayer}
            opacityValue={opacityValue}
            centerCoords={centerCoords}
            hostname={hostname}
          />
          <div className="proto-slider-container">
            <Slider
              min={0}
              max={100}
              value={opacityValue}
              orientation="vertical"
              onChange={(val) => this.props.dispatch(setOpacity(val))}
            />
          </div>
          <MapDrawer content="proto" />
        </div>
        {showModal ? (
          <MapModal
            toggleModal={this.toggleModal}
            title={modalContent.title}
            content={modalContent.content}
          />
        ) : null}
      </React.Fragment>
    ) : (
      <Loading />
    );
  }
}
function mapStateToProps({ data, mapControls, indra }) {
  //data logic
  const { gsLayers, uhiLayerDetails } = data;
  const { hostname } = indra;
  const { pathname } = window.location;

  //filter gsLayers
  const filteredLayers = gsLayers.filter((layer) => {
    return (
      layer.route === pathname && layer.wmsLayerName.includes("heat_index")
    ); //by heat_index
  });
  const morningTraverse = filteredLayers[1].name;

  //centerCoords
  let centerCoords;
  if (uhiLayerDetails === null) {
    centerCoords = null;
  } else {
    centerCoords = getCenterCoordsFromBoundingBox(
      uhiLayerDetails.coverage.latLonBoundingBox
    );
  }

  //mapControls
  const { currentLayer, opacityValue } = mapControls;
  let uhiLayer;
  if (currentLayer === null) {
    uhiLayer = null;
  } else {
    uhiLayer = Object.values(currentLayer.layer)[0];
  }

  return {
    currentLayer,
    opacityValue,
    filteredLayers,
    morningTraverse,
    uhiLayerDetails,
    uhiLayer,
    centerCoords,
    hostname,
  };
}
export default connect(mapStateToProps)(ProtoContainer);
