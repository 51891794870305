import React from "react";
import Drawer from "react-motion-drawer";
import "./MapDrawer.scss";
import * as asphalt from "./../../images/asphalt-and-development.png";
import * as greening from "./../../images/greening-and-development.png";
import * as uhiEvening from "./../../images/evening-uhi-white.png";
import * as logo from "./../../images/ui-blue-block.png";
import * as thermoRisk from "./../../images/thermo-risk.png";
const UhiDrawerContent = props => {
  return (
    <div>
      <h3>Scenarios of Changing the Built Environment</h3>
      <p>
        We used computer models to describe two scenarios that reflect decisions
        that can either amplify or cool the neighborhoods. As you move the
        slider to the left and right, the city changes in color, which
        represents results from our heat models for all areas of the City. The
        first (on the left side of the screen) represents removing existing
        trees and grass, and replacing those areas with black asphalt. The
        second (on the right side of the screen) is the use of effective cooling
        strategies in public and private areas.
      </p>
      <div className="raves-drawer-image">
        <div>
          <img src={asphalt} alt="asphalt and development" />
        </div>
        <div style={{ height: "144px", width: "170px" }}>
          <img
            src={greening}
            alt="greening and development"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      </div>
      <p>
        Though the temperatures are different in all areas based on the specific
        scenario, some areas vary as much as 20-degrees Fahrenheit. By using the
        zoom-in/out icon you can see how these changes are apparent on the
        landscape. While zoomed into a specific area of the city, hover your
        cursor over a specific neighborhoods to see the difference of
        temperatures across the two scenarios.
      </p>
      <p>
        What decisions would you make given these outcomes? We can also look at
        individual developments, such as modeling scenarios for individual
        buildings or city blocks. Get in touch with us and let's discuss what
        scenarios you would like to test.
      </p>
    </div>
  );
};

const RavesDrawerContent = () => {
  return (
    <div>
      <h3>Social Vulnerability to Extreme Heat</h3>
      <p>
        What does this mean? The darker red areas are the hottest part of town.
        As you select different times of the day -- morning (6am), afternoon
        (3pm), or evening (7pm) -- you'll see how different neighborhoods
        experience different temperatures as the sun rises and sets.
      </p>
      <img src={uhiEvening} alt="urban-heat-evening" />
      <p>
        Using your cursor, hover over any neighborhood to assess the
        distribution of potential vulnerable communities. Evidence from past
        heat waves suggest that those who are older, lower income, communities
        of color, and having lower formal education are most at risk from heat
        waves. Using the ThermoRisk graphic see if you can find which areas
        contain the greatest social vulnerability?
      </p>
      <img
        src={thermoRisk}
        alt="thermo risk graphic"
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          width: "60%"
        }}
      />
      <p>
        What actions would you take to reduce risks based on these findings. How
        can you accelerate programs the reduce social vulnerabilities? Contact
        us to create a customizable social vulnerability assessment for your
        community.
      </p>
    </div>
  );
};

const ProtoDrawerContent = () => {
  return (
    <div>
      <h3>Prototype</h3>
      <p>Content is still under review.</p>
      <p></p>
      <p></p>
    </div>
  );
};

class MapDrawer extends React.Component {
  state = {
    showDrawer: false,
    zIndex: 9998
  };

  renderContentSwitch = content => {
    switch (content) {
      case "uhi":
        return <UhiDrawerContent />;
      case "raves":
        return <RavesDrawerContent />;
      case "proto":
        return <ProtoDrawerContent />;
      default:
        return <ProtoDrawerContent />;
    }
  };
  //open the side drawer from button
  openDrawer = () => {
    this.setState({ showDrawer: true, zIndex: 10000 });
  };

  //open the side drawer from button
  closeDrawer = () => {
    this.setState({ showDrawer: false, zIndex: 9998 });
  };

  //close the drawer after opened
  toggleDrawer = showDrawer => {
    // deal with the zIndex of the crawer button
    let zIndex;
    if (showDrawer) zIndex = 10000;
    else zIndex = 9998;

    this.setState(prevState => ({ showDrawer, zIndex }));
  };

  //deal with the zIndex changing

  render() {
    const { showDrawer, zIndex } = this.state;
    const { content } = this.props;
    return (
      <React.Fragment>
        <div
          className="outer-map-drawer-button"
          onClick={() => {
            this.openDrawer();
          }}
        >
          MORE INFO
        </div>
        <Drawer
          open={showDrawer}
          width={400}
          zIndex={zIndex} //set this so that the entire button can be hovered on
          onChange={item => {
            this.toggleDrawer(item);
          }}
          className="map-drawer"
        >
          {val => (
            <React.Fragment>
              <div className="inner-map-drawer-button-container">
                <div style={{ height: "57px", width: "75px" }}>
                  <img
                    src={logo}
                    alt="capa-logo"
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
                <div style={{ flex: 1 }} />

                <div>
                  <div
                    className="inner-map-drawer-button"
                    onClick={() => {
                      this.closeDrawer();
                    }}
                  >
                    X
                  </div>
                </div>
              </div>
              <div className="drawer-content-container">
                {this.renderContentSwitch(content)}
              </div>
            </React.Fragment>
          )}
        </Drawer>
      </React.Fragment>
    );
  }
}

export default MapDrawer;
