import React from 'react';
import * as spinner from './../../images/Spinner-1s-200px.gif';
import './Loading.scss';
// import styleVars from './../../css/colors.scss';
import PropTypes from 'prop-types';

// const styles = {
//   content: {
//     display: 'table',
//     texAlign: 'center',
//     fontSize: '40px',
//     // marginTop: '300px',
//     margin: '0 auto',
//     color: styleVars.dark5
//     // paddingTop: '200px'
//   }
// };

//this component was updated to include
//the babel transform class properties
class Loading extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    speed: PropTypes.number.isRequired
  };

  //this is where we add default props
  static defaultProps = {
    text: 'Loading',
    speed: 300
  };

  state = {
    //old way
    //text: props.text
    //new
    text: this.props.text
  };

  componentDidMount() {
    const { text, speed } = this.props;
    const stopper = text + '...';

    this.interval = window.setInterval(() => {
      this.state.text === stopper
        ? this.setState(() => ({ text: this.props.text }))
        : this.setState(prevState => ({ text: prevState.text + '.' }));
    }, speed);
  }

  componentWillUnmount() {
    window.clearInterval(this.interval);
  }

  render() {
    return (
      // <div className="loading-container">
      //   <p style={styles.content}>{this.state.text}</p>

      // </div>
      <div className="outer">
        <div className="middle">
          <div className="inner">
            <img src={spinner} alt="loading" />
          </div>
        </div>
      </div>
    );
  }
}

export default Loading;
