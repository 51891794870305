import React from 'react';
import styleVars from './../../css/colors.scss';
import './UHI.scss';
// import { fetchData } from '../../utils/fetchUtils';
import { Map, GeoJSON, TileLayer } from 'react-leaflet';
import L from 'leaflet';
// import PropTypes from 'prop-types';
import SideBySide from './SideBySide';

class GeoJSONWithLayer extends React.Component {
  //style function
  getStyle(feature) {
    return { opacity: 0, fillOpacity: 0 };
  }

  //create the highlight function
  highlightFeature(e) {
    this.geoJSON.leafletElement.resetStyle(e.target);
    var layer = e.target;

    layer.setStyle({
      color: 'white',
      stroke: true,
      fill: true,
      weight: 5,
      fillOpacity: 0.7,
      fillColor: styleVars.dark2,
      opacity: 1
    });

    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  }

  handleOnEachFeature = (feature, layer) => {
    layer.on({
      mouseover: e => {
        //set the feature in state
        //THIS MUST GO FIRST BEFORE HIGHTLIGHT FEATURE!!!!!!
        this.props.setFeature(e.target.feature);
        //highlight the layer on the way in
        this.highlightFeature(e);
      },
      mouseout: e => {
        //reset the geojson layer on the way out
        
        //COMMENTED THESE OUT TO REMOVE GITCHYNESS 
        //WHEN HOVERING OVER THE CHARTS DIV 
        this.geoJSON.leafletElement.resetStyle(e.target);
        // this.props.setFeature(null);
      }
    });
  };

  render() {
    return (
      <GeoJSON
        ref={ref => (this.geoJSON = ref)}
        {...this.props}
        onEachFeature={this.handleOnEachFeature}
        style={this.getStyle}
      />
    );
  }
}

// GeoJSONWithLayer.defaultProps = {
//   popupContent: ''
// };

class UHIMap extends React.Component {
  state = {
    lat: 45.52345,
    lng: -122.6762,
    zoom: 12,
    open: false
  };

  componentDidMount() {
    const leafletMap = this.leafletMap.leafletElement;
    leafletMap.on('zoomend', () => {
      this.setState({ zoom: leafletMap.getZoom() });
    });

    //set max zoom here
    leafletMap.setMaxZoom(16);
    leafletMap.setMinZoom(11);
  }

  componentDidUpdate(prevProps) {}

  render() {
    const {
      leftLayer,
      rightLayer,
      data,
      // chartOpen,
      setFeature,
      transparencyValue
    } = this.props;
    const position = [this.state.lat, this.state.lng];
    const { zoom } = this.state;

    return (
      <Map
        center={position}
        zoom={zoom}
        maxBounds={[
          //south west
          [45.079339209738094, -121.9317626953125],
          //north east
          [45.865150166790585, -123.46160888671875]
        ]}
        ref={map => (this.leafletMap = map)}
      >
        {zoom >= 12 ? ( //show this only at specific zoom level
          <GeoJSONWithLayer
            // popupContent={'Some content goes here...'}
            key={data}
            data={data}
            setFeature={setFeature}
          />
        ) : null}
        {zoom >= 14 ? (
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
            subdomains="abcd"
            minZoom={11}
            maxZoom={16}
            ext="png"
          />
        ) : (
          <TileLayer
            url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.{ext}"
            attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            subdomains="abcd"
            minZoom={11}
            maxZoom={16}
            ext="png"
          />
        )}
        {/* the right and left layers can oly take one el each at the moment*/}
        <SideBySide
          ref={ref => (this.sideBySide = ref)}
          rightLayers={[rightLayer]}
          leftLayers={[leftLayer]}
          transparencyValue={transparencyValue}
        />
      </Map>
    );
  }
}

export default UHIMap;
