import React from 'react';
// import ReactTooltip from 'react-tooltip';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import { fetchCBGData } from '../../utils/fetchUtils';
import UHIMap from './UHIMap';
import UHICharts from './UHICharts';
import MapModal from './../Utils/MapModal';
import MapDrawer from './../Utils/MapDrawer';
import Loading from './../Utils/Loading';
import { tileLayers } from '../../layers/layers';
// import * as mitigation from './../../images/mitigation-white.png';
// import * as paving from './../../images/paving-white.png';
// import styleVars from './../../css/colors.scss';

//content for modal
const modalContent = {
  title: 'Scenarios of Changing the Built Environment',
  content:
    'Do you ever wonder what summers in your region will feel like in the ' +
    'coming decade? As the planet warms, the infrastructure decisions we ' +
    'make can either amplify extreme heat or help to cool our cities and ' +
    'regions. Using computer models and municipal infrastructure data, ' +
    'CAPA Insights offers a suit of products that help to describe the ' +
    'implications of changing the built environment. Whether at the scale ' +
    'of a city-block or an entire city, we employ state of the art models ' +
    'that use scenarios to describe how proposed changes can affect ' +
    'ambient temperatures. In this scenario, we build off several earlier ' +
    'projects to describe two scenarios -- paving and greening -- and how ' +
    'they can change the experience of temperatures throughout the City ' +
    'of Portland (OR). Take a look to see how the choices we make can ' +
    'help or hurt us.'
};

//images for map selector
// const controlImages = [paving, mitigation];

// const MapControls = props => {
//   const { controller, uhiLayerName } = props;

//   return [/*'Baseline',*/ 'Paving', 'Mitigation'].map((item, index) => (
//     <div
//       data-tip={item + ' Scenario'}
//       className="control-item"
//       key={item}
//       onClick={() => controller(item, props.side)}
//       style={
//         item === uhiLayerName
//           ? { border: `1px solid ${styleVars.light1}` }
//           : null
//       }
//     >
//       <img src={controlImages[index]} alt={item} />
//       <ReactTooltip />
//     </div>
//   ));
// };

class UHIContainer extends React.Component {
  state = {
    currentFeature: null,
    // chartOpen: false,
    uhiLayerName: 'Mitigation',
    leftLayer: tileLayers.lNoGreenLayerStretch,
    rightLayer: tileLayers.rAllCombLayerStretch,
    transparencyValue: 90,
    data: null,
    showModal: true
  };

  //mentod to set cahrt open state
  //done to deal with "glitchy" chart when hovering over
  //chart div at same time as hovering over geojson
  toggleChart = () => {
    console.log('toggle chart');
    this.setState(prevState => ({
      chartOpen: !prevState.chartOpen
    }));
  };
  //method to toggle modal
  toggleModal = () => {
    this.setState({ showModal: false });
  };

  //set object with all the tile layers
  setTileLayer = (name, pane) => {
    const lTilesController = {
      // Baseline: tileLayers.lOriginalBLayerStretch,
      Paving: tileLayers.lNoGreenLayerStretch,
      Mitigation: tileLayers.lAllCombLayerStretch
    };

    const rTilesController = {
      // Baseline: tileLayers.rOriginalBLayerStretch,
      Paving: tileLayers.rNoGreenLayerStretch,
      Mitigation: tileLayers.rAllCombLayerStretch
    };

    if (pane === 'left') {
      this.setState({ leftLayer: lTilesController[name], uhiLayerName: name });
    }

    if (pane === 'right') {
      this.setState({ rightLayer: rTilesController[name], uhiLayerName: name });
    }
  };

  //send this as props down to the geojson layer
  setCurrentFeature = currentFeature => {
    this.setState({ currentFeature });
  };

  handleSliderChange = transparencyValue => {
    this.setState({
      transparencyValue: transparencyValue
    });
  };

  async componentDidMount() {
    const data = await fetchCBGData('/pdxBgs_origB_allComb.json');
    this.setState({ data });
  }

  render() {
    const {
      currentFeature,
      // uhiLayerName,
      // chartOpen,
      rightLayer,
      leftLayer,
      transparencyValue,
      data,
      showModal
    } = this.state;

    return data ? (
      <React.Fragment>
        <div className="uhi-container">
          {/* THE FOLLOWING BLOCK IS COMMENTED OUT BUT CAN BE USED TO ADD LAYER CONTROLS */}
          {/* <div className="uhi-controls-container">
            <div>
              <MapControls
                controller={this.setTileLayer}
                side="right"
                uhiLayerName={uhiLayerName}
              />
            </div>
          </div> */}
          <div className="uhi-map">
            <UHIMap
              setFeature={this.setCurrentFeature}
              data={data}
              // chartOpen={chartOpen}
              rightLayer={rightLayer}
              leftLayer={leftLayer}
              transparencyValue={transparencyValue}
            />
          </div>
          <div className="uhi-charts-container">
            <UHICharts
              currentFeature={currentFeature}
              toggleChart={this.toggleChart}
            />
          </div>
          <div className="slider-container">
            <Slider
              min={0}
              max={100}
              value={transparencyValue}
              orientation="vertical"
              onChange={this.handleSliderChange}
            />
          </div>
          <MapDrawer content="uhi" />
        </div>
        {showModal ? (
          <MapModal
            toggleModal={this.toggleModal}
            title={modalContent.title}
            content={modalContent.content}
          />
        ) : null}
      </React.Fragment>
    ) : (
      <Loading />
    );
  }
}

export default UHIContainer;
