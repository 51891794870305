import React from 'react';
import { Map, TileLayer, GeoJSON } from 'react-leaflet';
import styleVars from './../../css/colors.scss';
// import PropTypes from 'prop-types';
import './Raves.scss';

class GeoJSONWithLayer extends React.Component {
  //style function
  getStyle(feature) {
    return { opacity: 0, fillOpacity: 0 };
  }

  //create the highlight function
  highlightFeature(e) {
    this.geoJSON.leafletElement.resetStyle(e.target);
    var layer = e.target;

    layer.setStyle({
      color: styleVars.dark2,
      stroke: true,
      fill: true,
      weight: 5,
      fillOpacity: 0.7,
      fillColor: styleVars.dark2,
      opacity: 1
    });

    // if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
    //   layer.bringToFront();
    // }
  }

  handleOnEachFeature = (feature, layer) => {
    layer.on({
      mouseover: e => {
        //set the feature in state
        //THIS MUST GO FIRST BEFORE HIGHTLIGHT FEATURE!!!!!!
        this.props.setFeature(e.target.feature);
        //highlight the layer on the way in
        this.highlightFeature(e);
      },
      mouseout: e => {
        //reset the geojson layer on the way out

        //COMMENTED THESE OUT TO REMOVE GITCHYNESS
        //WHEN HOVERING OVER THE CHARTS DIV
        // this.geoJSON.leafletElement.resetStyle(e.target);
        // this.props.setFeature(null);
      }
    });
  };

  render() {
    return (
      <GeoJSON
        ref={ref => (this.geoJSON = ref)}
        {...this.props}
        onEachFeature={this.handleOnEachFeature}
        style={this.getStyle}
      />
    );
  }
}

class RavesMap extends React.Component {
  state = {
    lat: 45.52345,
    lng: -122.6762,
    zoom: 12
  };

  getLayer() {
    return this.layer1.leafletElement;
  }

  componentDidMount() {
    const leafletMap = this.leafletMap.leafletElement;
    leafletMap.on('zoomend', () => {
      this.setState({ zoom: leafletMap.getZoom() });
    });

    //set zoom bounds here
    leafletMap.setMinZoom(11);
  }

  render() {
    const { setFeature, data, uhiLayer, transparencyValue } = this.props;
    const position = [this.state.lat, this.state.lng];

    return (
      <Map
        center={position}
        zoom={this.state.zoom}
        maxBounds={[
          //south west
          [45.079339209738094, -121.9317626953125],
          //north east
          [45.865150166790585, -123.46160888671875]
        ]}
        ref={map => (this.leafletMap = map)}
      >
        <TileLayer
          url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.{ext}"
          // attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
          ext="png"
          minZoom={11}
          maxZoom={17}
        />
        <TileLayer url={uhiLayer} opacity={transparencyValue / 100} />
        <GeoJSONWithLayer
          // popupContent={'Some content goes here...'}
          key={data}
          data={data}
          style={this.getStyle}
          setFeature={setFeature}
        />
      </Map>
    );
  }
}

export default RavesMap;
