import {
  GET_GS_LAYERS,
  GET_CBG_DATA,
  GET_UHI_LAYER_DETAILS
} from "../actions/data";

const initialState = { gsLayers: null, cbg: null, uhiLayerDetails: null };

export default function data(state = initialState, action) {
  switch (action.type) {
    case GET_GS_LAYERS:
      return { ...state, ...action.payload };
    case GET_CBG_DATA:
      return { ...state, ...action.payload };
    case GET_UHI_LAYER_DETAILS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
