import React from "react";
import { VictoryBar, VictoryLabel, VictoryStack } from "victory";
import styleVars from "./../../css/colors.scss";
import "./Raves.scss";

class RavesCharts extends React.Component {
  render() {
    const featureProperties = this.props.currentFeature.properties;
    const population = featureProperties.pop_13;

    const chartData = {
      under18: {
        label: "Under 18:",
        value: featureProperties.PRCT_18,
        remainder: 100 - featureProperties.PRCT_18,
        fill: "#e41a1c"
      },
      over65: {
        label: "Over 65:",
        value: featureProperties.PRCT_65,
        remainder: 100 - featureProperties.PRCT_65,
        fill: "#377eb8"
      },
      under50Poverty: {
        label: "Under 50% Poverty Line:",
        value: featureProperties.Prct_Poor_,
        remainder: 100 - featureProperties.Prct_Poor_,
        fill: "#4daf4a"
      },
      noDiploma: {
        label: "Without HS Diploma/GED:",
        value: featureProperties.HS_Dip_Pcn,
        remainder: 100 - featureProperties.HS_Dip_Pcn,
        fill: "#984ea3"
      },
      nonWhite: {
        label: "Non-White:",
        value: featureProperties.non_w_pct,
        remainder: 100 - featureProperties.non_w_pct,
        fill: "#ff7f00"
      }
    };
    return (
      <div className="raves-charts-container">
        <div className="raves-charts-title">ThermoRisk</div>
        {Object.keys(chartData).map(item => (
          <div key={item}>
            <div>{chartData[item].label}</div>
            <div>
              <VictoryStack
                horizontal
                style={{
                  data: { stroke: styleVars.dark5, strokeWidth: 2 },
                  labels: {
                    fill: chartData[item].fill,
                    fontSize: "35px"
                  }
                }}
                colorScale={[chartData[item].fill, styleVars.leafletcolor]}
                height={30}
                domainPadding={{ x: [10, 40] }}
                labels={d => {
                  const labelVal = 100 - d.y;
                  return `${labelVal}%`;
                }}
                labelComponent={<VictoryLabel textAnchor="inherit" />}
              >
                <VictoryBar
                  barWidth={35}
                  data={[{ x: "a", y: chartData[item].value }]}
                />
                <VictoryBar
                  barWidth={35}
                  data={[{ x: "a", y: chartData[item].remainder }]}
                />
              </VictoryStack>
            </div>
          </div>
        ))}
        <hr />
        <div className="raves-charts-pop">Population: {population}</div>
      </div>
    );
  }
}

export default RavesCharts;

////////////////////////////////////////////

/* <svg width={200} height={50}>
<VictoryBar
  horizontal
  style={{
    //   parent: { width: '200px' },
    data: { fill: '#c43a31' }
  }}
  standalone={false}
  width={100}
  height={50}
  barWidth={40}
  padding={0}
  data={[{ x: 'dogs', y: 10 }]}
  labels={d => d.y}
  labelComponent={<VictoryLabel dx={-30} />}
  domain={{ x: [0, 10] }}
/>
</svg>
<div>hello</div>
<svg width={200} height={50}>
<VictoryBar
  horizontal
  style={{
    //   parent: { width: '200px' },
    data: { fill: '#c43a31' }
  }}
  standalone={false}
  width={100}
  height={50}
  barWidth={40}
  padding={0}
  data={[{ x: 'dogs', y: 3 }]}
  labels={d => d.y}
  labelComponent={<VictoryLabel dx={-30} />}
  domain={{ x: [0, 10] }}
/>
</svg> */

/* <VictoryChart
domainPadding={25}
style={{ parent: { maxWidth: '300px ' } }}
>
<VictoryAxis dependentAxis />
<VictoryBar
  horizontal
  style={{
    parent: { width: '200px' },
    data: { fill: '#c43a31' }
  }}
  data={[
    { x: 'birds', y: 2 },
    { x: 'cats', y: 3 },
    { x: 'dogs', y: 5 },
    { x: 'fish', y: 4 },
    { x: 'frogs', y: 6 }
  ]}
  categories={{
    x: ['birds', 'cats', 'dogs', 'fish', 'frogs']
  }}
/>
</VictoryChart> */
/* <VictoryBar
horizontal
style={{
  parent: { width: '200px' },
  data: { fill: '#c43a31' }
}}
data={[{ x: 'birds', y: 2 }]}
range={{ y: [0, 10] }}
/>
<VictoryBar
horizontal
style={{
  parent: { width: '200px' },
  data: { fill: '#c43a31' }
}}
data={[{ x: 'cats', y: 3 }]}
range={{ y: [0, 10] }}
/>{' '}
<VictoryBar
horizontal
style={{
  parent: { width: '200px' },
  data: { fill: '#c43a31' }
}}
data={[{ x: 'dogs', y: 5 }]}
range={{ y: [0, 10] }}
/> */
